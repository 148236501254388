import { arrayOf, bool, func, number, shape } from 'prop-types'

import {
  formatOptionLabel,
  formatOptionsSummary,
  reduceOptionCents,
} from '../../../../../shared/js/options-utils'
import { formatPrice } from '../../../../../shared/js/formatters'
import OptionsSummaryLabel from '../../../../../shared/js/components/OptionsSummaryLabel'
import { ProductOptionValuePropTypes } from '../../../../../shared/js/prop-types'

// Massage selected options to get a user readable summary
export default function OptionSummary({
  deleteDisabled,
  hidePrice,
  id,
  onCancel,
  values,
}) {
  const { options, selected } = values[0] || {}
  if (!selected || options.length === 0 || !onCancel) {
    return null
  }

  const labels = formatOptionsSummary(options)
  if (labels.length === 0) {
    return null
  }

  const { optionsCents, optionsTaxCents } = reduceOptionCents(undefined, {
    values,
  })

  const labelsContainers = labels.map(([title, label], index) => (
    <p key={`${title}-${index}`}>
      <small>
        <OptionsSummaryLabel title={title} label={label} />
        {!hidePrice && ` - ${formatPrice(optionsCents + optionsTaxCents)}`}
        {!deleteDisabled && (
          <a
            href='#'
            onClick={handleCancel}
            alt={`Supprimer l’option ${formatOptionLabel(label)}`}
          >
            (supprimer)
          </a>
        )}
      </small>
    </p>
  ))

  return <div className='u-mb-2'>{labelsContainers}</div>

  function handleCancel(event) {
    event.preventDefault()
    onCancel(id)
  }
}

OptionSummary.propTypes = {
  deleteDisabled: bool,
  hidePrice: bool,
  id: number.isRequired,
  onCancel: func,
  values: arrayOf(shape(ProductOptionValuePropTypes)).isRequired,
}
