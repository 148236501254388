import { useEffect, useRef } from 'react'

import { adjustLabelForOptionality } from './Option'
import { ProductOptionWithNamePropTypes } from '../../../../../shared/js/prop-types'

const TextArea = ({
  details,
  disabled,
  id,
  label,
  name,
  onChange,
  required,
  setValue,
  triggerFocus,
  values: [value = ''],
  valid,
}) => {
  const inputId = `textarea-${id}`
  const descriptionId = details ? `description-textarea-${id}` : null
  const ariaDescribedBy = details ? { 'aria-describedby': descriptionId } : {}
  const fieldRef = useRef(null)

  if (!required) {
    label = adjustLabelForOptionality(label)
  }

  // When autofocus is expected, trigger it,
  // then move smoothly into view
  useEffect(() => {
    if (fieldRef.current && triggerFocus) {
      fieldRef.current.scrollIntoView({ behavior: 'smooth' })
      fieldRef.current.focus({ preventScroll: true })
    }
  }, [triggerFocus])

  return (
    <div className='u-mb-2'>
      <label
        className='c-label c-label--image'
        htmlFor={inputId}
        {...ariaDescribedBy}
      >
        {label}
      </label>
      <textarea
        aria-required={required}
        aria-invalid={!valid}
        disabled={disabled}
        id={inputId}
        name={name}
        onChange={handleChange}
        ref={fieldRef}
      >
        {value?.text}
      </textarea>
      {!valid && (
        <p className='c-message c-message--error'>
          Vous devez renseigner cette valeur
        </p>
      )}
      {details && (
        <p id={descriptionId} className='c-message c-message--help'>
          {details}
        </p>
      )}
    </div>
  )

  function handleChange({ target: { value: text } }) {
    onChange(id, [{ ...value, text }])
  }
}

TextArea.defaultProps = { values: [] }
TextArea.propTypes = ProductOptionWithNamePropTypes

export default TextArea
